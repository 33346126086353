import React from "react"
import styles from "../bathDetail.module.scss"
import { Link } from "gatsby"
import Menubar from "../../components/menubar/menubarEng"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from "react-bootstrap/Carousel"
import Figure from "react-bootstrap/Figure"
import Footer from "../../components/footer/footerEng"
import ProjectTopImage from "../../img/banyoTop.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/scss/alice-carousel.scss"

const Arch = () => {
  const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "arch1.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      two: file(relativePath: { eq: "arch4.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      three: file(relativePath: { eq: "arch2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      four: file(relativePath: { eq: "renk_ral9004Parlak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      five: file(relativePath: { eq: "renk_ral9010Parlak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)

  return (
    <div className={styles.productDetailContainer}>
      <div className={styles.leftE}></div>
      <div className={styles.centerSite}>
        <Menubar />
        <div className={styles.imageTopProductDetailContainer}>
          <img
            src={ProjectTopImage}
            alt="projects"
            className={styles.imageTopProductDetail}
          />
          <div className={styles.verticalLineWhiteProductDetail}>
            <div className={styles.socialMediaIcons}>
              <a
                href="https://www.facebook.com/atiaahsap"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="1x"
                  className={styles.ffIcon}
                />
              </a>
              <br />
              <a
                href="https://www.instagram.com/atiaahsap/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="1x"
                  className={styles.igIcon}
                />
              </a>
            </div>
          </div>
          <span className={styles.topSpanProductDetail}>
            ARCH
            <br />
            <Link to="/bathroom" className={styles.backLinkPD}>
              ALL BATHS <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </span>
        </div>
        <div className={styles.pdContainer}>
          <div className={styles.pDmiddleContainer}>
            <Carousel indicators={false} fade={false} id="pdetailCar">
              <Carousel.Item>
                <img
                  src={data.one.childImageSharp.fluid.src}
                  alt="first of item detail"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={data.two.childImageSharp.fluid.src}
                  alt="second of item detail"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={data.three.childImageSharp.fluid.src}
                  alt="third of item detail"
                />
              </Carousel.Item>
            </Carousel>
            <h3 style={{ marginTop: "3%", marginBottom: "3%" }}>
              Color Options
            </h3>
            <div className={styles.PDMiddleTextBox}>
              <AliceCarousel
                buttonsDisabled={true}
                responsive={{ 1023: { items: 6 }, 765: { items: 4 } }}
              >
                <Figure>
                  <Figure.Image
                    src={data.four.childImageSharp.fluid.src}
                    alt="color1"
                    className={styles.figureImage}
                  />
                  <Figure.Caption>
                    Glossy Lacquer Options <br />
                    RAL9004
                  </Figure.Caption>
                </Figure>
                <Figure>
                  <Figure.Image
                    src={data.five.childImageSharp.fluid.src}
                    alt="color1"
                    className={styles.figureImage}
                  />
                  <Figure.Caption>
                    Glossy Lacquer Options <br />
                    RAL9010
                  </Figure.Caption>
                </Figure>
              </AliceCarousel>
              <h5 style={{ marginTop: "4%" }}>Design: Esat Fişek</h5>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className={styles.rightE}></div>
    </div>
  )
}

export default Arch
